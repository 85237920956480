import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';

const UpcomingVehicles = lazyImport(() => import('@/features/block'), 'UpcomingVehicles');
const ActiveVehicles = lazyImport(() => import('@/features/block'), 'ActiveVehicles');
const Offers = lazyImport(() => import('@/features/block'), 'Offers');
const Purchases = lazyImport(() => import('@/features/block'), 'Purchases');
const NotFound = lazyImport(() => import('@/features/misc'), 'NotFound');

const Management = () => {
  return (
    <MainLayout>
      <Suspense fallback={<> TODO </>}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const adminRoutes = [
  {
    path: '/admin',
    element: <Management />,
    children: [
      { path: 'upcoming', element: <UpcomingVehicles /> },
      { path: 'active', element: <ActiveVehicles /> },
      { path: 'offers', element: <Offers /> },
      { path: 'purchases', element: <Purchases /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];
