import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const Landing = () => {
  const navigate = useNavigate();
  useEffect(() => {
      navigate('auth/login', { replace: true });
  });

  return (
    <div>
      This is a landing page <Link to='auth/login'>login</Link>
    </div>
  );
};
