import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from '@/contexts/auth';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CssBaseline from '@mui/material/CssBaseline';
import { queryClient } from '@/lib/react-query';
import { theme } from '@/lib/mui-theme';

const ErrorFallback = () => {
  return (
    <Box fullWidth sx={{ p: 20 }} align='center'>
      <h2>Ooops, something went wrong :( </h2>
      <Button
        variant='outlined'
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </Button>
    </Box>
  );
};

const theme_1 = createTheme({
  typography: {
    button: {
      // color:'#67b084',
      textTransform: 'none',
    },
  },
  palette: {
    ...theme.palette,
    primary: {
      main: '#52a9dd',
      contrastText: '#fff',
    },
    background: {
      default: 'rgb(244 244 244)',
    },
    success: {
      main: '#67b084',
      contrastText: '#fff',
    },
    error: {
      main: '#ff6b6b',
      contrastText: '#fff',
    },
    warning: {
      main: '#ffa05c',
      contrastText: '#fff',
    }
  },
});

export const AppProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme_1}>
      <CssBaseline />
      <React.Suspense
        fallback={
          <Box p={10} align='center'>
            <LinearProgress />
          </Box>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
              <AuthProvider>
                <Router>{children}</Router>
              </AuthProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </React.Suspense>
    </ThemeProvider>
  );
};
