import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getUser, loginWithSmsCode, requestLoginSmsCode } from '@/features/auth';
import storage from '@/utils/storage';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function initAuthContext() {
  const AuthContext = React.createContext(null);
  AuthContext.displayName = 'AuthContext';

  async function loadUser() {
    if (storage.getToken()) {
      const data = await getUser();
      return data;
    }
    return null;
  }

  async function loginFn(data) {
    const { Jwt, User } = await loginWithSmsCode(data);
    storage.setToken(Jwt);
    return User;
  }

  async function logoutFn() {
    storage.clearToken();
    window.location.assign(window.location.origin);
  }

  function AuthProvider({ children }) {
    const queryClient = useQueryClient();
    const key = 'auth-user';

    const {
      data: user,
      error,
      status,
      isLoading,
      isIdle,
      isSuccess,
      refetch,
    } = useQuery({
      queryKey: [key],
      queryFn: loadUser,
    });

    const setUser = React.useCallback(
      (data) => queryClient.setQueryData([key], data),
      [queryClient]
    );

    const requestCodeMutation = useMutation({
      mutationFn: requestLoginSmsCode,
      mutationKey: 'auth-request-sms-code',
    });

    const loginMutation = useMutation({
      mutationFn: loginFn,
      mutationKey: 'auth-login',
      onSuccess: (user) => {
        setUser(user);
      },
    });

    const logoutMutation = useMutation({
      mutationFn: logoutFn,
      mutationKey: 'auth-logout',
      onSuccess: () => {
        queryClient.clear();
      },
    });

    const value = React.useMemo(
      () => ({
        user,
        error,
        requestCodeMutation,
        loginMutation,
        refetchUser: refetch,
        logout: logoutMutation.mutate,
        isLoggingOut: logoutMutation.isLoading,
      }),
      [
        user,
        error,
        refetch,
        requestCodeMutation,
        loginMutation,
        logoutMutation.mutate,
        logoutMutation.isLoading,
      ]
    );

    if (isLoading || isIdle) {
      return (
        <Box p={10} align='center'>
          <LinearProgress />
        </Box>
      );
    }

    if (error) {
      logoutMutation.mutate();
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }

  function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
  }

  return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth };
}

export const { AuthProvider, AuthConsumer, useAuth } = initAuthContext();
