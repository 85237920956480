const _API_URLS = {
  USER_API_URL: '',
  ADMIN_API_URL: '',
};
if (process.env.REACT_APP_ENV == 'qa') {
  _API_URLS.USER_API_URL = 'https://shopicar-user-api-qa.azurewebsites.net';
  _API_URLS.ADMIN_API_URL = 'https://shopicar-admin-api-qa.azurewebsites.net';
} else if (process.env.REACT_APP_ENV == 'development') {
  _API_URLS.USER_API_URL = 'https://shopicar-user-api-dev.azurewebsites.net';
  _API_URLS.ADMIN_API_URL = 'https://shopicar-admin-api-dev.azurewebsites.net';
} else if (process.env.REACT_APP_ENV == 'production') {
  _API_URLS.USER_API_URL = 'https://user-api.shopicar.com';
  _API_URLS.ADMIN_API_URL = 'https://admin-api.shopicar.com';
} else if (process.env.REACT_APP_ENV == 'local') {
  _API_URLS.USER_API_URL = 'https://localhost:44372';
  _API_URLS.ADMIN_API_URL = 'https://localhost:44368';
} else {
  _API_URLS.USER_API_URL = process.env.USER_API_URL;
  _API_URLS.ADMIN_API_URL = process.env.ADMIN_API_URL;
}

export const USER_API_URL = _API_URLS.USER_API_URL;
export const ADMIN_API_URL = _API_URLS.ADMIN_API_URL;
