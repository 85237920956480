import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2ba9e1',
      contrastText: '#fff',
    },
    secondary: {
      main: '#555',
      contrastText: '#fff',
    },
    black: {
      main: '#444546',
    },
    white: {
      main: '#fff',
    },
  },
});
