import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('auth/login', { replace: true });
  });
  return (
    <Box sx={{ width: '100%', maxWidth: 700, margin: 'auto' }} align='center'>
      <Typography variant='h4' component='h4' sx={{ m: 3 }}>
        404
      </Typography>
      <Button variant='contained' to='auth/login'>
        Sign in
      </Button>
    </Box>
  );
};
