import { Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './Login';
import { LoginForm } from '../components/LoginForm';
import { SmsCodeForm } from '../components/SmsCodeForm';
import { NotFound } from '@/features/misc';
import { useAuth } from '@/contexts/auth';

export const AuthRoutes = () => {
  const { user } = useAuth();

  if (user) {
    return (
      <Routes>
        <Route path='/login/*' element={<Navigate to='/block/live' />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path='/login' element={<Login />}>
        <Route index element={<LoginForm />} />
        <Route path='sms-code' element={<SmsCodeForm />} />
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};
