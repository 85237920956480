import { lazyImport } from '@/utils/lazyImport';

const BlockRoutes = lazyImport(() => import('@/features/block'), 'BlockRoutes');

export const protectedRoutes = [
  {
    path: '/block/*',
    element: <BlockRoutes />,
  },
];
