import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

const wrapperStyle = {
  bottom: 0,
  right: 0,
  left: 0,
  color: '#939b9f',
  padding: 2,
  textAlign: 'center',
};

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box component='footer' sx={wrapperStyle}>
      <Stack alignItems='center' spacing={1}>
        <Typography>Shopicar Inc. - {t('All rights reserved')}</Typography>
        <Typography>{t('ShopicarConsentMessage')}</Typography>
        <Stack direction='row' spacing={1}>
          <Link to='/terms-and-conditions' style={{ textDecoration: 'none' }}>
            <Typography color='primary'>{t('Terms and conditions')}</Typography>
          </Link>
          <Divider orientation='vertical' flexItem color='#939b9f' />
          <Link to='/privacy-policy' style={{ textDecoration: 'none' }}>
            <Typography color='primary'>{t('Privacy policy')}</Typography>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};
