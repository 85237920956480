import { Layout } from '../components/Layout';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

export const Login = () => {
  return (
    <Layout title='Log in to your account'>
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};
