import { useRoutes } from 'react-router-dom';
import { Landing, TermsAndConditions, PrivacyPolicy, NotFound } from '@/features/misc';
import { useAuth } from '@/contexts/auth';
import { adminRoutes } from './admin';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const { user } = useAuth();

  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '*', element: <NotFound /> },
  ];

  const appRoutes = user
    ? user.UserTypeId === 6
      ? [...adminRoutes, ...protectedRoutes, ...publicRoutes]
      : [...protectedRoutes, ...publicRoutes]
    : publicRoutes;

  const element = useRoutes([...appRoutes, ...commonRoutes]);

  return <>{element}</>;
};
