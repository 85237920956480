import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { BrandingLogo } from '../Elements';
import { Head } from '../Head';
import { Footer } from '../Footer';
import { useAuth } from '@/contexts/auth';
import { useTranslation } from 'react-i18next';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { postUpdateUserLanguage } from '../../features/block/api/postUpdateUserLanguage';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const MainLayout = ({ children, title }) => {
  const [userAnchor, setUserAnchor] = React.useState(null);
  const isUserMenuOpen = Boolean(userAnchor);
  const { logout, user } = useAuth();

  const { t, i18n } = useTranslation();

  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
    try {
      await postUpdateUserLanguage({
        Id: user.User_ID,
        LanguageId: lng === 'en' ? 1 : 2,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const userName = user.FirstName + ' ' + user.LastName;
  const company = user.Company
    ? user.Company + ' #' + user.Company_ID
    : user.CompanyName + ' #' + user.CompanyId;

  return (
    <>
      <Head title={title} />
      {/* <AppBar position='sticky' color='white' elevation={1}> */}
      <AppBar position='static' color='white' elevation={1}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box height={30} sx={{ flexGrow: 1 }}>
              <BrandingLogo height='100%' />
            </Box>
            <Button
              endIcon={<ExpandMoreIcon />}
              color='black'
              // size='large'
              onClick={(event) => setUserAnchor(event.currentTarget)}
              sx={{ textTransform: 'capitalize' }}
            >
              <Stack spacing={0} alignItems='flex-end'>
                <Typography
                  fontSize='16px'
                  fontWeight='500'
                  mb={0}
                  sx={{ lineHeight: 1 }}
                >
                  {userName}
                </Typography>
                <Typography fontSize='13px' fontWeight='400'>
                  {company}
                </Typography>
              </Stack>
            </Button>
            <StyledMenu
              anchorEl={userAnchor}
              open={isUserMenuOpen}
              onClose={() => setUserAnchor(null)}
            >
              {i18n.language == 'en' ? (
                <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
              ) : (
                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
              )}
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={logout}>
                <LogoutIcon />
                &nbsp;
                {t('Sign out')}
              </MenuItem>
            </StyledMenu>
          </Toolbar>
        </Container>
      </AppBar>
      <Box display='flex' flexGrow={1}>
        {children}
      </Box>
      <Footer />
    </>
  );
};
